import styled from "styled-components"
import { Canvas } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import * as THREE from "three"

import PlanMesh from "./components/PlanMesh"

import globalStyles from "@data/globalStyles"

function Plan3DViewer() {
	return (
		<PlanWrapper>
			<Label>
				<h1>FLOOR</h1>
				<h1>PLAN</h1>
			</Label>
			<Canvas
				camera={{
					fov: 20,
					position: new THREE.Vector3(1, 1, 1)
				}}>
				<OrbitControls
					maxPolarAngle={1.5}
					minPolarAngle={0.5}
					// enableZoom={false}
					minDistance={1}
					maxDistance={5}
					autoRotate={false}
					rotateSpeed={0.3}
				/>
				<PlanMesh />
			</Canvas>
		</PlanWrapper>
	)
}

export default Plan3DViewer

const PlanWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const Label = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	right: 6rem;

	& h1 {
		text-align: right;
		font-size: 12rem;
		line-height: 12rem;
		letter-spacing: 4rem;
		font-family: titleLight;
		color: ${globalStyles.colors.secondary};
		opacity: 0.6;
	}
`
