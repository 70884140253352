import { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import DefaultLayout from "./DefaultLayout"

import { useStore } from "@state/store"
import backUpData from "@data/temp/fullUnitData"
import trimAPIData from "@utils/trimAPIData"
import PortraitPrompt from "@components/PortraitPrompt"

function App() {
	const fullUnits = useStore(s => s.fullUnits)
	const setFullUnits = useStore(s => s.setFullUnits)
	const navigating = useStore(s => s.navigating)
	const setNavigating = useStore(s => s.setNavigating)

	useEffect(() => {
		if (fullUnits === null) {
			fetch(`https://api-gateway.vmiservers.com/surf/units`)
				.then(res => res.json())
				.then(res => {
					const trimmed = trimAPIData(res.payload)
					localStorage.setItem("1515SurfUnits", JSON.stringify(trimmed))
					setFullUnits(trimmed)
				})
				.catch(error => {
					const stored = localStorage.getItem("1515SurfUnits") || null
					setFullUnits(stored ? JSON.parse(stored) : trimAPIData(backUpData))
					console.error(
						"There has been a problem with your fetch operation:",
						error
					)
				})
		}
	}, [])

	useEffect(() => {
		if (navigating ) {
			setTimeout(() => {
				setNavigating(false)
			}, 600)
		}
	}, [navigating])
	return (
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<DefaultLayout />} />
			</Routes>
			<PortraitPrompt/>
		</BrowserRouter>
	)
}

export default App
