import { useEffect, useState } from "react"
import styled from "styled-components"

import TextFrame from "@components/TextFrame/TextFrame"
import CubeIcon from "@components/SVGIcons/CubeIcon"
import PlanIcon from "@components/SVGIcons/PlanIcon"
import TelescopeIcon from "@components/SVGIcons/TelescopeIcon"
import ApartmentView from "@components/ApartmentView"
import FloorplanViewer from "@components/FloorplanViewer"
import Plan3DViewer from "@components/Plan3DViewer"
import TransitionDiv from "@components/TransitionDiv"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function ApartmentCard() {
	const [show, setShow] = useState(false)
	const currentApt = useStore(s => s.currentApt)
	const setCurrentApt = useStore(s => s.setCurrentApt)
	const fullUnits = useStore(s => s.fullUnits)
	const cardSectionIndex = useStore(s => s.cardSectionIndex)
	const setCardSectionIndex = useStore(s => s.setCardSectionIndex)
	const favorites = useStore(s => s.favorites)
	const iconsArr = [CubeIcon, PlanIcon, TelescopeIcon]
	const contentArr = [<Plan3DViewer />, <FloorplanViewer />, <ApartmentView />]

	useEffect(() => {
		if (cardSectionIndex === 0 || cardSectionIndex === 2) {
			setShow(false)
			setTimeout(() => {
				setShow(true)
			}, 3000)
		}
	}, [cardSectionIndex])

	const handleClickNext = () => {
		const unitID = favorites.find(element => element === currentApt.unit)
		console.log(unitID)
		const currentIndex  = favorites.indexOf(unitID as never)
		const nextIndex =
		currentIndex === favorites.length - 1 ? 0 : currentIndex + 1
		setCurrentApt(fullUnits.find(element => element.unit === favorites[nextIndex]))
	}

	const handleClickPrev = () => {
		const unitID = favorites.find(element => element === currentApt.unit)
		const currentIndex  = favorites.indexOf(unitID as never)
		const prevIndex =
		currentIndex === 0 ? favorites.length - 1 : currentIndex - 1
		setCurrentApt(fullUnits.find(element => element.unit === favorites[prevIndex]))
	}


	return (
		<CardWrapper>
			<Content>
				<TransitionDiv
					childrenArr={contentArr}
					triggerIndex={cardSectionIndex}
					durationMS={1000}
				/>
			</Content>
			<RightCorner>
				<TextFrame
					rightText={`LVL ${currentApt?.floor}`}
					bottomText={`APT ${currentApt?.unit}`}
					style={{
						position: "relative",
						width: "clamp(200px, 30rem, 500px)",
						height: "clamp(350px, 45rem, 700px)",
						fontSize: "clamp(10px, 2rem, 30px)",
						fill: globalStyles.colors.white
					}}
				/>
			</RightCorner>
			<LeftCorner>
				<Info
					style={{
						opacity: cardSectionIndex === 2 ? 0 : 1
					}}
				>
					<p>{`${currentApt?.beds} ${
						currentApt?.beds > 1 ? "BEDS" : "BED"
					}`}</p>
					<p>{`${currentApt?.baths} ${
						currentApt?.baths > 1 ? "BATHS" : "BATH"
					}`}</p>
					<p>{`${currentApt?.area} SQFT`}</p>
					<p>{`$ ${currentApt?.price}`}</p>
				</Info>
				{iconsArr.map((Icon, i) => {
					return (
						<Icon
							strokeWidth={3}
							strokeColor={globalStyles.colors.main}
							bgColor={
								cardSectionIndex === i
									? globalStyles.colors.secondary
									: globalStyles.colors.gray
							}
							className="icon"
							key={`icon${i}`}
							onClick={() => {
								setCardSectionIndex(i)
							}}
						/>
					)
				})}
				<NextPrevArrows>
					<Arrow onClick={handleClickPrev}>
						<h1>&larr;</h1>
					</Arrow>
					<Arrow onClick={handleClickNext}>
						<h1>&rarr;</h1>
					</Arrow>
				</NextPrevArrows>
			</LeftCorner>
		</CardWrapper>
	)
}

export default ApartmentCard

const CardWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.gray};

	.icon{
	  height: clamp(80px, 10rem, 500px);
		touch-action: auto;
		pointer-events: auto;
	}
`

const RightCorner = styled.div`
	width: fit-content;
	height: fit-content;
	position: absolute;
	bottom: 0;
	right: 2rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	touch-action: none;
	pointer-events: none;
	color: ${globalStyles.colors.white};
`

const LeftCorner = styled.div`
	width: clamp(200px, 40rem, 600px);
	height: clamp(400px, 10rem, 500px);
	position: absolute;
	bottom: 2.5rem;
	left: 2rem;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap: 3rem;
	touch-action: none;
	pointer-events: none;
`

const Content = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const Info = styled.div`
	position: absolute;
	bottom: 25%;
	width: 100%;
	font-family: title;
	transition: opacity 0.6s ease-in;
	touch-action: none;
	pointer-events: none;

	& p {
		width: 100%;
		text-align: left;
		font-size: clamp(20px, 5rem, 100px);
		color: ${globalStyles.colors.main};
	}
`

const NextPrevArrows = styled.div`
	display: flex;
	align-items: center;
	font-size: clamp(15px, 1.5rem, 50px);
	height: clamp(80px, 10rem, 500px);
`

const Arrow = styled.div`
	pointer-events: auto;
	touch-action: auto;
	cursor: pointer;
	color: ${globalStyles.colors.main};
	padding: 0 2rem;
`
