import { create } from "zustand"

type aspect = "N" | "E" | "S" | "W"
interface TAptObject  {
  unit: number,
  floor: number,
  area: number,
  baths: number,
  beds: number,
  price: number,
  aspect: aspect[],
  type: string,
  unitStatus: string,
}
interface InitialState {
  //GENERAL
  setLocalState: (stateToUpdate: any, data: any) => void
  isPortrait: boolean
  //NAVIGATION
  navigating: boolean
  setNavigating: (v: boolean) => void
  //APARTMENT CARD
  fullUnits: null | TAptObject[]
  setFullUnits: (v: TAptObject[]) => void
  currentApt: TAptObject | null
  setCurrentApt: (v: any) => void
  cardOpen: boolean
  setCardOpen: (v: boolean) => void
  cardSectionIndex: number
  setCardSectionIndex: (v: number) => void
  //FAVORITES
  favorites: [] | number[]
  setFavorites: (v: number[] | []) => void
  sharePopup: boolean
  setSharePopup: (v: boolean) => void
}

export const useStore = create<InitialState>()((set, get) => ({
  //GENERAL
  setLocalState: (stateToUpdate: any, data: any) => {
      set({ [stateToUpdate]: data })
  },
  isPortrait: innerHeight > innerWidth,
  navigating: true,
  setNavigating: (v) => set({navigating: v}),
  //APARTMENT CARD
  fullUnits: null,
  setFullUnits: (v) => set({fullUnits: v}),
  currentApt: null,
  setCurrentApt: (v) => set({currentApt: v}),
  cardOpen: false,
  setCardOpen: (v) => set({cardOpen: v}),
  cardSectionIndex: 0,
  setCardSectionIndex: (v) => set({cardSectionIndex: v}),
  //FAVORITES
  favorites: [],
  setFavorites: (v) => set({favorites: v}),
  sharePopup: false,
  setSharePopup: (v) => set({sharePopup: v}),
}))
