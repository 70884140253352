import { Fragment, useEffect } from "react"
import { useLocation } from "react-router-dom"

import Content from "./Content"

import { useStore } from "@state/store"

function DefaultLayout() {
	const setLocalState = useStore(s => s.setLocalState)
	const location = useLocation()

	function setOrientation(){
			setLocalState("isPortrait", innerHeight > innerWidth)
	}

	useEffect(() => {
		window.addEventListener("resize", setOrientation)
		const encodedFavs = location.search
		if (encodedFavs) {
			const decoded = atob(encodedFavs?.split("?")[1])
			const favArr = decoded.split(",").map(f => f)
			setLocalState("favorites", favArr)
		}		
	}, [])
	
	return (
		<Fragment>
			<Content />
		</Fragment>
	)
}

export default DefaultLayout
