export default function trimAPIData(arr) {
	const trimmed = arr.map(obj => {
		const unitID =
			obj["ApartmentName"].charAt(0) === "0"
				? obj["ApartmentName"].substring(1)
				: obj["ApartmentName"]
		if (unitIndex[unitID]) {
			return {
				unit: unitID,
				floor: getFloor(obj["ApartmentName"]),
				area: Number(obj["SQFT"]),
				baths: Number(obj["Baths"]),
				beds: Number(obj["Beds"]),
				price: Number(obj["MaximumRent"]),
				aspect: unitIndex[unitID].aspect.split("-"),
				type: unitIndex[unitID].type,
				unitStatus: obj["UnitStatus"],
				available: statuses[obj["UnitStatus"]]
			}
		}
	})

	return trimmed.filter(item => item)
}

function getFloor(apartmentName) {
	if (apartmentName.includes("P")) {
		return Number(apartmentName.charAt(2)) + 23
	} else {
		return Number(apartmentName.substring(0, 2))
	}
}

const statuses = {
	"Vacant Unrented Not Ready": true,
	"Vacant Unrented Ready": true,
	"Vacant Rented Not Ready": false,
	"Notice Unrented": true,
	"Noticed Rented": false,
	"Occupied No Notice": false
}

const unitIndex = {
	"331": { type: "H", aspect: "N" },
	"431": { type: "H", aspect: "N" },
	"531": { type: "H", aspect: "N" },
	"631": { type: "H", aspect: "N" },
	"726": { type: "H", aspect: "N" },
	"734": { type: "II", aspect: "E" },
	"827": { type: "II", aspect: "E" },
	"927": { type: "II", aspect: "E" },
	"1029": { type: "II", aspect: "E" },
	"1129": { type: "II", aspect: "E" },
	"1229": { type: "II", aspect: "E" },
	"1329": { type: "II", aspect: "E" },
	"1429": { type: "II", aspect: "E" },
	"1529": { type: "II", aspect: "E" },
	"1629": { type: "II", aspect: "E" },
	"1729": { type: "II", aspect: "E" },
	"1829": { type: "II", aspect: "E" },
	"1929": { type: "II", aspect: "E" },
	"2029": { type: "II", aspect: "E" },
	"2129": { type: "II", aspect: "E" },
	"2229": { type: "II", aspect: "E" },
	"2329": { type: "II", aspect: "E" },
	"1025": { type: "EE", aspect: "W" },
	"1125": { type: "EE", aspect: "W" },
	"1225": { type: "EE", aspect: "W" },
	"1325": { type: "EE", aspect: "W" },
	"1425": { type: "EE", aspect: "W" },
	"1525": { type: "EE", aspect: "W" },
	"1625": { type: "EE", aspect: "W" },
	"1725": { type: "EE", aspect: "W" },
	"1825": { type: "EE", aspect: "W" },
	"1925": { type: "EE", aspect: "W" },
	"2025": { type: "EE", aspect: "W" },
	"2125": { type: "EE", aspect: "W" },
	"2225": { type: "EE", aspect: "W" },
	"2325": { type: "EE", aspect: "W" },
	"324": { type: "D", aspect: "S" },
	"424": { type: "D", aspect: "S" },
	"524": { type: "D", aspect: "S" },
	"624": { type: "D", aspect: "S" },
	"731": { type: "FF", aspect: "W" },
	"824": { type: "FF", aspect: "W" },
	"924": { type: "FF", aspect: "W" },
	"1026": { type: "FF", aspect: "W" },
	"1126": { type: "FF", aspect: "W" },
	"1226": { type: "FF", aspect: "W" },
	"1326": { type: "FF", aspect: "W" },
	"1426": { type: "FF", aspect: "W" },
	"1526": { type: "FF", aspect: "W" },
	"1626": { type: "FF", aspect: "W" },
	"1726": { type: "FF", aspect: "W" },
	"1826": { type: "FF", aspect: "W" },
	"1926": { type: "FF", aspect: "W" },
	"2026": { type: "FF", aspect: "W" },
	"2126": { type: "FF", aspect: "W" },
	"2226": { type: "FF", aspect: "W" },
	"2326": { type: "FF", aspect: "W" },
	"332": { type: "I", aspect: "N" },
	"432": { type: "I", aspect: "N" },
	"532": { type: "I", aspect: "N" },
	"632": { type: "I", aspect: "N" },
	"727": { type: "I", aspect: "N" },
	"444": { type: "Q", aspect: "W" },
	"544": { type: "Q", aspect: "W" },
	"342": { type: "Q", aspect: "W" },
	"341": { type: "Q", aspect: "W" },
	"343": { type: "R", aspect: "W" },
	"445": { type: "R", aspect: "W" },
	"545": { type: "R", aspect: "W" },
	"439": { type: "N", aspect: "W" },
	"440": { type: "N", aspect: "W" },
	"441": { type: "N", aspect: "W" },
	"539": { type: "N", aspect: "W" },
	"540": { type: "N", aspect: "W" },
	"541": { type: "N", aspect: "W" },
	"639": { type: "N", aspect: "W" },
	"640": { type: "N", aspect: "W" },
	"641": { type: "N", aspect: "W" },
	"337": { type: "N", aspect: "W" },
	"338": { type: "N", aspect: "W" },
	"339": { type: "N", aspect: "W" },
	"636": { type: "K", aspect: "W" },
	"436": { type: "K", aspect: "W" },
	"536": { type: "K", aspect: "W" },
	"442": { type: "O", aspect: "W" },
	"542": { type: "O", aspect: "W" },
	"642": { type: "O", aspect: "W" },
	"340": { type: "O", aspect: "W" },
	"730": { type: "J", aspect: "N" },
	"335": { type: "J", aspect: "N" },
	"435": { type: "J", aspect: "N" },
	"535": { type: "J", aspect: "N" },
	"635": { type: "J", aspect: "N" },
	"823": { type: "CC", aspect: "S" },
	"923": { type: "CC", aspect: "S" },
	"1023": { type: "CC", aspect: "S" },
	"1123": { type: "CC", aspect: "S" },
	"1223": { type: "CC", aspect: "S" },
	"1323": { type: "CC", aspect: "S" },
	"1423": { type: "CC", aspect: "S" },
	"1523": { type: "CC", aspect: "S" },
	"1623": { type: "CC", aspect: "S" },
	"1723": { type: "CC", aspect: "S" },
	"1823": { type: "CC", aspect: "S" },
	"1923": { type: "CC", aspect: "S" },
	"2023": { type: "CC", aspect: "S" },
	"2123": { type: "CC", aspect: "S" },
	"2223": { type: "CC", aspect: "S" },
	"2323": { type: "CC", aspect: "S" },
	"325": { type: "E", aspect: "S" },
	"425": { type: "E", aspect: "S" },
	"525": { type: "E", aspect: "S" },
	"625": { type: "E", aspect: "S" },
	"733": { type: "HH", aspect: "E-N" },
	"826": { type: "HH", aspect: "E-N" },
	"926": { type: "HH", aspect: "E-N" },
	"1028": { type: "HH", aspect: "E-N" },
	"1128": { type: "HH", aspect: "E-N" },
	"1228": { type: "HH", aspect: "E-N" },
	"1328": { type: "HH", aspect: "E-N" },
	"1428": { type: "HH", aspect: "E-N" },
	"1528": { type: "HH", aspect: "E-N" },
	"1628": { type: "HH", aspect: "E-N" },
	"1728": { type: "HH", aspect: "E-N" },
	"1828": { type: "HH", aspect: "E-N" },
	"1928": { type: "HH", aspect: "E-N" },
	"2028": { type: "HH", aspect: "E-N" },
	"2128": { type: "HH", aspect: "E-N" },
	"2228": { type: "HH", aspect: "E-N" },
	"2328": { type: "HH", aspect: "E-N" },
	"323": { type: "C", aspect: "S" },
	"423": { type: "C", aspect: "S" },
	"523": { type: "C", aspect: "S" },
	"623": { type: "C", aspect: "S" },
	"330": { type: "G", aspect: "S" },
	"430": { type: "G", aspect: "S" },
	"530": { type: "G", aspect: "S" },
	"630": { type: "G", aspect: "S" },
	"725": { type: "G", aspect: "S" },
	"822": { type: "BB", aspect: "E-S" },
	"922": { type: "BB", aspect: "E-S" },
	"1022": { type: "BB", aspect: "E-S" },
	"1122": { type: "BB", aspect: "E-S" },
	"1222": { type: "BB", aspect: "E-S" },
	"1322": { type: "BB", aspect: "E-S" },
	"1422": { type: "BB", aspect: "E-S" },
	"1522": { type: "BB", aspect: "E-S" },
	"1622": { type: "BB", aspect: "E-S" },
	"1722": { type: "BB", aspect: "E-S" },
	"1822": { type: "BB", aspect: "E-S" },
	"1922": { type: "BB", aspect: "E-S" },
	"2022": { type: "BB", aspect: "E-S" },
	"2122": { type: "BB", aspect: "E-S" },
	"2222": { type: "BB", aspect: "E-S" },
	"2322": { type: "BB", aspect: "E-S" },
	"333": { type: "G", aspect: "N" },
	"334": { type: "G", aspect: "N" },
	"433": { type: "G", aspect: "N" },
	"434": { type: "G", aspect: "N" },
	"533": { type: "G", aspect: "N" },
	"534": { type: "G", aspect: "N" },
	"633": { type: "G", aspect: "N" },
	"634": { type: "G", aspect: "N" },
	"728": { type: "G", aspect: "N" },
	"729": { type: "G", aspect: "N" },
	"326": { type: "G", aspect: "S" },
	"327": { type: "G", aspect: "S" },
	"328": { type: "G", aspect: "S" },
	"329": { type: "G", aspect: "S" },
	"426": { type: "G", aspect: "S" },
	"427": { type: "G", aspect: "S" },
	"428": { type: "G", aspect: "S" },
	"429": { type: "G", aspect: "S" },
	"526": { type: "G", aspect: "S" },
	"527": { type: "G", aspect: "S" },
	"528": { type: "G", aspect: "S" },
	"529": { type: "G", aspect: "S" },
	"626": { type: "G", aspect: "S" },
	"627": { type: "G", aspect: "S" },
	"628": { type: "G", aspect: "S" },
	"629": { type: "G", aspect: "S" },
	"722": { type: "G", aspect: "S" },
	"723": { type: "G", aspect: "S" },
	"724": { type: "G", aspect: "S" },
	"443": { type: "P", aspect: "W" },
	"543": { type: "P", aspect: "W" },
	"643": { type: "P", aspect: "W" },
	"355": { type: "V", aspect: "E" },
	"457": { type: "V", aspect: "E" },
	"557": { type: "V", aspect: "E" },
	"651": { type: "V", aspect: "E" },
	"721": { type: "AA", aspect: "E" },
	"821": { type: "AA", aspect: "E" },
	"921": { type: "AA", aspect: "E" },
	"1021": { type: "AA", aspect: "E" },
	"1121": { type: "AA", aspect: "E" },
	"1221": { type: "AA", aspect: "E" },
	"1321": { type: "AA", aspect: "E" },
	"1421": { type: "AA", aspect: "E" },
	"1521": { type: "AA", aspect: "E" },
	"1621": { type: "AA", aspect: "E" },
	"1721": { type: "AA", aspect: "E" },
	"1821": { type: "AA", aspect: "E" },
	"1921": { type: "AA", aspect: "E" },
	"2021": { type: "AA", aspect: "E" },
	"2121": { type: "AA", aspect: "E" },
	"2221": { type: "AA", aspect: "E" },
	"2321": { type: "AA", aspect: "E" },
	"653": { type: "V", aspect: "E" },
	"322": { type: "B", aspect: "E-S" },
	"422": { type: "B", aspect: "E-S" },
	"522": { type: "B", aspect: "E-S" },
	"622": { type: "B", aspect: "E-S" },
	"732": { type: "GG", aspect: "W-N" },
	"825": { type: "GG", aspect: "W-N" },
	"925": { type: "GG", aspect: "W-N" },
	"1027": { type: "GG", aspect: "W-N" },
	"1127": { type: "GG", aspect: "W-N" },
	"1227": { type: "GG", aspect: "W-N" },
	"1327": { type: "GG", aspect: "W-N" },
	"1427": { type: "GG", aspect: "W-N" },
	"1527": { type: "GG", aspect: "W-N" },
	"1627": { type: "GG", aspect: "W-N" },
	"1727": { type: "GG", aspect: "W-N" },
	"1827": { type: "GG", aspect: "W-N" },
	"1927": { type: "GG", aspect: "W-N" },
	"2027": { type: "GG", aspect: "W-N" },
	"2127": { type: "GG", aspect: "W-N" },
	"2227": { type: "GG", aspect: "W-N" },
	"2327": { type: "GG", aspect: "W-N" },
	"438": { type: "M", aspect: "W" },
	"538": { type: "M", aspect: "W" },
	"638": { type: "M", aspect: "W" },
	"336": { type: "M", aspect: "W" },
	PH1C: { type: "LL", aspect: "W" },
	PH2B: { type: "LL", aspect: "W" },
	PH3B: { type: "LL", aspect: "W" },
	"437": { type: "L", aspect: "W-N" },
	"537": { type: "L", aspect: "W-N" },
	"637": { type: "L", aspect: "W-N" },
	"230": { type: "S", aspect: "E" },
	"344": { type: "S", aspect: "E" },
	"446": { type: "S", aspect: "E" },
	"546": { type: "S", aspect: "E" },
	"321": { type: "A", aspect: "E" },
	"421": { type: "A", aspect: "E" },
	"521": { type: "A", aspect: "E" },
	"621": { type: "A", aspect: "E" },
	"229": { type: "T", aspect: "E" },
	"345": { type: "T", aspect: "E" },
	"447": { type: "T", aspect: "E" },
	"547": { type: "T", aspect: "E" },
	"221": { type: "U", aspect: "E" },
	"222": { type: "U", aspect: "E" },
	"223": { type: "U", aspect: "E" },
	"224": { type: "U", aspect: "E" },
	"225": { type: "U", aspect: "E" },
	"226": { type: "U", aspect: "E" },
	"227": { type: "U", aspect: "E" },
	"228": { type: "U", aspect: "E" },
	"346": { type: "U", aspect: "E" },
	"347": { type: "U", aspect: "E" },
	"348": { type: "U", aspect: "E" },
	"349": { type: "U", aspect: "E" },
	"350": { type: "U", aspect: "E" },
	"351": { type: "U", aspect: "E" },
	"352": { type: "U", aspect: "E" },
	"353": { type: "U", aspect: "E" },
	"354": { type: "U", aspect: "E" },
	"448": { type: "U", aspect: "E" },
	"449": { type: "U", aspect: "E" },
	"450": { type: "U", aspect: "E" },
	"451": { type: "U", aspect: "E" },
	"452": { type: "U", aspect: "E" },
	"453": { type: "U", aspect: "E" },
	"454": { type: "U", aspect: "E" },
	"455": { type: "U", aspect: "E" },
	"456": { type: "U", aspect: "E" },
	"548": { type: "U", aspect: "E" },
	"549": { type: "U", aspect: "E" },
	"550": { type: "U", aspect: "E" },
	"551": { type: "U", aspect: "E" },
	"552": { type: "U", aspect: "E" },
	"553": { type: "U", aspect: "E" },
	"554": { type: "U", aspect: "E" },
	"555": { type: "U", aspect: "E" },
	"556": { type: "U", aspect: "E" },
	"644": { type: "U", aspect: "E" },
	"645": { type: "U", aspect: "E" },
	"646": { type: "U", aspect: "E" },
	"647": { type: "U", aspect: "E" },
	"648": { type: "U", aspect: "E" },
	"649": { type: "U", aspect: "E" },
	"650": { type: "U", aspect: "E" },
	"356": { type: "W", aspect: "E" },
	"458": { type: "W", aspect: "E" },
	"558": { type: "W", aspect: "E" },
	"652": { type: "W", aspect: "E" },
	"654": { type: "W", aspect: "E" },
	"1024": { type: "DD", aspect: "S-W" },
	"1124": { type: "DD", aspect: "S-W" },
	"1224": { type: "DD", aspect: "S-W" },
	"1324": { type: "DD", aspect: "S-W" },
	"1424": { type: "DD", aspect: "S-W" },
	"1524": { type: "DD", aspect: "S-W" },
	"1624": { type: "DD", aspect: "S-W" },
	"1724": { type: "DD", aspect: "S-W" },
	"1824": { type: "DD", aspect: "S-W" },
	"1924": { type: "DD", aspect: "S-W" },
	"2024": { type: "DD", aspect: "S-W" },
	"2124": { type: "DD", aspect: "S-W" },
	"2224": { type: "DD", aspect: "S-W" },
	"2324": { type: "DD", aspect: "S-W" },
	"231": { type: "X", aspect: "W" },
	PH1D: { type: "MM", aspect: "W-N" },
	PH2C: { type: "MM", aspect: "W-N" },
	PH3C: { type: "MM", aspect: "W-N" },
	PH1E: { type: "NN", aspect: "E-N" },
	PH2D: { type: "NN", aspect: "E-N" },
	PH3D: { type: "NN", aspect: "E-N" },
	PH1A: { type: "JJ", aspect: "E-S" },
	PH2A: { type: "JJ", aspect: "E-S" },
	PH3A: { type: "JJ", aspect: "E-S" },
	PH1B: { type: "KK", aspect: "S-W" }
}
