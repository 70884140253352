import { useState, useEffect } from "react"
import styled from "styled-components"
import { QRCodeSVG } from "qrcode.react"

import Form from "./components/Form"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function Share() {
	const favorites = useStore(s => s.favorites)
	const isPortrait = useStore(s => s.isPortrait)
	const encodedFav = btoa(`${favorites?.join(",")}`)
	return (
		<ShareWrapper className="centerREL">
			<QRWrapper className={isPortrait ? "centerREL flexCenterColumn mobile" : "centerREL flexCenterRow"}>
				<Form />
				<QR
					value={`https://share.1515surf.app?${encodedFav}`}
					size={450}
					bgColor={`${globalStyles.colors.white}`}
					fgColor={globalStyles.colors.main}
					level="Q"
					includeMargin
					id="qr"
				/>
			</QRWrapper>
		</ShareWrapper>
	)
}

export default Share

const ShareWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background: ${globalStyles.colors.black}80;

	.mobile{
		height: 85%;
	}
`

const QRWrapper = styled.div`
	width: 80%;
  height: 70%;
  background-color: ${globalStyles.colors.white};
  box-shadow: 0 0 6rem ${globalStyles.colors.secondary}b3;
	aspect-ratio: 1/1;
	padding: 5%;
  gap: 2%;
`

const QR = styled(QRCodeSVG)`
	width: 100%;
	max-height: 40%;
	flex: 0.4 0.3 40%;
	aspect-ratio: 1/1;
`
