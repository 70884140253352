import styled from "styled-components"

type TFrameProps = {
	topText?: string,
	rightText?: string,
	bottomText?: string,
	leftText?: string,
	textProps?: { [x: string]: any },
	[x: string]: any
}

function TextFrame({
	topText,
	rightText,
	bottomText,
	leftText,
	textProps,
	children,
	...props
}: TFrameProps) {
	return (
		<TextFrameWrapper {...props}>
			<Top>
				<p>{topText}</p>
			</Top>
			<Bottom>
				<p>{bottomText}</p>
			</Bottom>
			<Left>
				<p>{leftText}</p>
			</Left>
			<Right>
				<p>{rightText}</p>
			</Right>
			<Content>
				{children}
			</Content>
		</TextFrameWrapper>
	)
}

export default TextFrame

const TextFrameWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-areas:
		"x a a a a a a a a a"
		"d e e e e e e e e b"
		"d e e e e e e e e b"
		"d e e e e e e e e b"
		"d e e e e e e e e b"
		"d e e e e e e e e b"
		"d e e e e e e e e b"
		"d e e e e e e e e b"
		"d e e e e e e e e b"
		"d c c c c c c c c y";
`

const Content = styled.div`
	grid-area: e;
`

const Text = styled.div`
	display: flex;

	& p {
		font-family: title;
		font-size: 200%;
		line-height: 1;
	}
`

const Top = styled(Text)`
	grid-area: a;
	align-items: flex-end;
`

const Bottom = styled(Text)`
	grid-area: c;
	justify-content: flex-end;
	align-items: flex-start;
`

const Left = styled(Text)`
	grid-area: d;
	writing-mode: vertical-rl;
`

const Right = styled(Text)`
	grid-area: b;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	padding-top: 5%;
`
