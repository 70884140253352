import { useRef, useEffect, useCallback } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import gsap from "gsap"

import CircleText from "@components/SVGIcons/CircleText"
import ShareIcon from "@components/SVGIcons/ShareIcon"
import FavCard from "@components/FavCardWrapper/FavCard"
import Popup from "@components/Popup"
import ApartmentCard from "@components/ApartmentCard"
import CloseIcon from "@components/SVGIcons/CloseIcon"
import ScrollIcon from "@components/SVGIcons/ScrollIcon"
import Share from "@components/Share"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import { randomNumberInRange } from "@utils/functions"

function Favorites() {
	const navigating = useStore(s => s.navigating)
	const favorites = useStore(s => s.favorites)
	const cardOpen = useStore(s => s.cardOpen)
	const sharePopup = useStore(s => s.sharePopup)
	const isPortrait = useStore(s => s.isPortrait)
	const setLocalState = useStore(s => s.setLocalState)
	const animated = useRef<HTMLDivElement[]>([])
	const wrapperRef = useRef<HTMLDivElement>()
	const topRef = useRef(0)
	const timeline = gsap.timeline({ delay: 0.8 })
	const label = ["LIVE", "OUTSIDE", "THE", "ORDINARY"]

	const handleRef = useCallback(
		(node, i) => {
			if (node) {
				animated.current[i] = node
			}
		},
		[favorites]
	)

	useEffect(() => {
		topRef.current = 0
		if (animated.current.length && !navigating && !cardOpen) {
			animated.current.forEach((elm, i) => {
				timeline.to(
					elm,
					{
						top: "25vh",
						rotate: `${randomNumberInRange(-22.5, 22.5)}deg`,
						opacity: 1,
						duration: 0.3,
						delay: randomNumberInRange(0.01, 0.05) * i
					},
					"<"
				)
			})
			timeline.add(() => {}, "+=0.5")
			animated.current.forEach((elm, i) => {
				topRef.current
				timeline.to(
					elm,
					{
						// opacity: 1,
						top: `${topRef.current}vh`,
						left: i % 2 === 0 ? "5vw" : isPortrait ? "5vw" : "50vw",
						duration: 0.5 + i / 100,
						ease: "power2.in",
						delay: randomNumberInRange(0.01, 0.05) * i
					},
					"<"
				)
				if (isPortrait) {
					topRef.current += 50
				} else {
					if (i % 2 !== 0) {
						topRef.current += 50
					}
				}
			})
		}
	}, [navigating, isPortrait, cardOpen])

	useEffect(() => {
		return () => {
			setLocalState("cardOpen", false)
		}
	}, [])

	return (
		<Wrapper ref={wrapperRef}>
			<Label>
				{label.map((txt, i) => {
					return (
						<h1
							key={`label${i}`}
							style={{
								animationDelay: `${i * 150}ms`
							}}>
							{txt}
						</h1>
					)
				})}
			</Label>
			{favorites.map((unitID, i) => {
				return (
					<CardWrapper
						key={`fav${i}`}
						ref={n => handleRef(n, i)}
						$portrait={isPortrait}>
						<FavCard
							unit={unitID}
							className={isPortrait ? "centerREL cardP" : "centerREL card"}
						/>
					</CardWrapper>
				)
			})}
			{favorites.length > 0 && (
				<ScrollPrompt>
					<ScrollIcon
						strokeColor={globalStyles.colors.secondary}
						strokeWidth={3}
					/>
				</ScrollPrompt>
			)}
			<SharePrompt
				onClick={() => {
					setLocalState("sharePopup", true)
				}}>
				<CircleText
					text={
						"\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f\u205fSHARE\u205f"
					}
					animated={!navigating}
					className="centerABS text"
				/>
				<ShareIcon
					strokeColor={globalStyles.colors.secondary}
					bgColor="transparent"
					strokeWidth={2}
					className="centerABS"
				/>
			</SharePrompt>
			<Popup
				openState={cardOpen}
				setOpenState={x => setLocalState("cardOpen", x)}
				durationMS={800}
				closeButton={
					<CloseIcon strokeWidth={5} strokeColor={globalStyles.colors.main} />
				}
				onClose={() => {
					setLocalState("currentApt", null)
					setLocalState("cardSectionIndex", 0)
				}}>
				<ApartmentCard />
			</Popup>
			<Popup
				openState={sharePopup}
				setOpenState={x => setLocalState("sharePopup", x)}
				durationMS={800}
				closeButton={
					<CloseIcon strokeWidth={5} strokeColor={globalStyles.colors.white} />
				}>
				<Share />
			</Popup>
		</Wrapper>
	)
}

export default Favorites

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: ${globalStyles.colors.main};
	white-space: normal;
	overflow: hidden scroll;
	touch-action: pan-y;
	pointer-events: auto;

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${globalStyles.colors.black}66;
		border-radius: 50px;
	}

	::-webkit-scrollbar-track {
		background-color: ${globalStyles.colors.secondary};
	}

	.card {
		max-width: 33vw;
		box-shadow: 5rem 5rem 5rem ${globalStyles.colors.black}44;
		touch-action: pan-y;
		pointer-events: auto;
	}

	.cardP {
		min-width: 80%;
		max-width: 90%;
		box-shadow: 5rem 5rem 5rem ${globalStyles.colors.black}44;
		touch-action: pan-y;
		pointer-events: auto;
	}
`

const SharePrompt = styled.div`
	position: fixed;
	bottom: 0;
	right: 1%;
	width: clamp(100px, 12rem, 600px);
	padding: 1rem;
	aspect-ratio: 1/1;
	border-radius: 100%;
	background: rgba(225, 225, 225, 0.01);
	backdrop-filter: blur(2rem);
	cursor: pointer;

	& text {
		font-size: clamp(138px, 12rem, 600px);
		font-family: title;
		fill: ${globalStyles.colors.secondary};
	}

	& svg {
		:nth-of-type(2) {
			width: 40%;
			transform: translate(-55%, -50%);
		}
	}
`

const ScrollPrompt = styled.div`
	position: fixed;
	right: 1rem;
	height: 12rem;
	width: 6rem;
	padding: 1rem 0 1rem 1rem;
`

const CardWrapper =
	styled.div <
	{ $portrait: boolean } >
	`
	position: absolute;
	display: inline-block;
	width: ${p => (p.$portrait ? "90%" : "38%")};
	height: 50vh;
	opacity: 1;
	top: -50vh;
	left: ${p => (p.$portrait ? "5vw" : "25vw")};
	touch-action: pan-y;
	pointer-events: auto;
`

const Label = styled.div`
	position: fixed;
	pointer-events: none;
	width: 100%;
	bottom: 0;
	margin-left: 1.2rem;

	& h1 {
		pointer-events: none;
		text-align: left;
		font-size: 12rem;
		line-height: 12rem;
		letter-spacing: 4rem;
		font-family: titleLight;
		color: ${globalStyles.colors.secondary};
		opacity: 0;
		animation-name: fadeIn;
		animation-duration: 1.8s;
		animation-fill-mode: forwards;
	}
`
