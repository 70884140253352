import { useEffect, useState, useRef, ReactNode } from "react"
import styled from "styled-components"

import CloseIcon from "@components/SVGIcons/CloseIcon"

import globalStyles from "@data/globalStyles"

function Popup({
	openState,
	setOpenState,
	children,
	durationMS,
	closeButton,
	onClose,
}: {
	openState: boolean,
	setOpenState: (v: boolean) => void,
	children: ReactNode,
	durationMS: number,
	closeButton?: ReactNode,
	onClose?: () => void
}) {
	const [visible, setVisible] = useState(false)
	const popupRef = useRef<HTMLDivElement>(null)

	function handleRef(node) {
		if (popupRef.current === null) {
			node.style.transitionDuration = `${durationMS}ms`
			setTimeout(() => {
				node.style.opacity = "1"
			}, 100)
			popupRef.current = node
		} else {
			return
		}
	}

	function handleClose() {
		popupRef.current.style.opacity = "0"
		setTimeout(() => {
			setVisible(false)
			setTimeout(() => {
				setOpenState(false)
				onClose && onClose()
				popupRef.current = null
			}, durationMS)
		}, durationMS)

	}

	useEffect(() => {
		if (visible) {
			popupRef.current.style.opacity = "0"
			setTimeout(() => {
				setVisible(openState)
				popupRef.current = null
			}, durationMS + 10)
		} else {
			setVisible(openState)
		}
	}, [openState])
	
	return (
		visible && (
			<PopupWrapper ref={node => node && handleRef(node)}>
				{children}
				{closeButton ? (
					<Close onClick={handleClose}>
						{closeButton}
					</Close>
				) : (
					<Close onClick={handleClose}>
						<CloseIcon
							strokeWidth={5}
							strokeColor={globalStyles.colors.white}
						/>
					</Close>
				)}
			</PopupWrapper>
		)
	)
}

export default Popup

const PopupWrapper = styled.div`
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition-property: opacity;
	transition-timing-function: ease-in-out;
`

const Close = styled.div`
	position: absolute;
	z-index: 1;
	width: clamp(30px, 2rem, 100px);
	aspect-ratio: 1/1;
	cursor: pointer;
	pointer-events: auto;
	top: 2rem;
	right: 2rem;
	display: grid;
	place-content: center;
	border-radius: 100%;
`
