import Favorites from "@pages/Favorites"

const routes = [
	{
		name: "HOME",
		path: "/",
		onNav: true,
		element: Favorites
	}
]

export default routes
