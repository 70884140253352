import styled from "styled-components"

import FloorplanManager from "@components/FloorplanManager"

import globalStyles from "@data/globalStyles"

function FloorplanViewer() {
	return (
		<ViewerWrapper>
			<Label>
				<h1>FLOOR</h1>
				<h1>PLAN</h1>
			</Label>
			{/* <img alt="" src="/images/plans/floorplan.png" className="centerABS" /> */}
			<FloorplanManager />
		</ViewerWrapper>
	)
}

export default FloorplanViewer

const ViewerWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const Label = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	right: 6rem;

	& h1 {
		text-align: right;
		font-size: 12rem;
		line-height: 12rem;
		letter-spacing: 4rem;
		font-family: titleLight;
		color: ${globalStyles.colors.secondary};
		opacity: 0.6;
	}
`
